import React from "react";
import Layout from "../components/layout";
import JotformEmbed from "react-jotform-embed";

const HowItWorksPage = () => {
  return (
    <Layout>
      <JotformEmbed src="https://form.jotform.com/212985327878069" className="JotFormEmbed" />
      <div style={{paddingBottom: '50px', background: "#ecedf3", borderTop: "5px solid #ecedf3", transform: "translateY(-3px)"}}></div>
    </Layout>
  );
};

export default HowItWorksPage;
